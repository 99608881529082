module.exports = {
  meta: {
    title: 'Tantogårdens Bangolf | Södermalm, Stockholm',
    description:
      'Tantogårdens Bangolf | Stockholms kanske bäst belägna minigolf bana, mitt i Tantolunden på Södermalm. Bara några minuter från Hornstull.',
    keywords: 'Bangolf, Minigolf, Tantolunden, Aktiviteter på Södermalm,',
  },
  publisher: 'Tantogårdens Bangolf | Minigolf på Södermalm, Stockholm', // Organization name used for SEO schema
  siteUrl: 'https://www.tantogarden.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  shortTitle: 'Tantogårdens Bangolf', // Used for App manifest e.g. Mobile Home Screen
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: 'src/images/tantogardens-bangolf-logo.png', // Logo used for SEO, RSS, and App manifest
  siteLogoSvg: 'src/images/tantogardens-bangolf-logo.svg',
  backgroundColor: '#c62828', // Used for Offline Manifest
  themeColor: '#c62828', // Used for Offline Manifest
  GOOGLE_MAPS_API_KEY: 'AIzaSyCic2qiok5vbrFaU1MtDzPIaGUz3dlGR9E',
};
