// Default font-size on body is 112.5%, 125% on tablet and 137.5% on desktop

export const fontSize = {
  goliath: '4rem',
  huge: '2.6rem',
  larger: '2rem',
  large: '1.5rem',
  medium: '1.25rem',
  default: '1rem',
  small: '0.875rem',
  smaller: '0.75rem',

  mediumStatic: '22px',
  defaultStatic: '20px',
  smallStatic: '16px',
};

export const fontWeight = {
  heaviest: 900,
  default: 400,
  light: 300,
};

export const screenSize = {
  xsMax: '599px',
  smMin: '600px',
  smMax: '768px',
  mdMin: '769px',
  mdMax: '1023px',
  lgMin: '1024px',
};

export const colors = {
  white: '#ffffff',
  black: '#000000',
  blue: '#32324e',
  blueDark: '#000022',
  grayLight: '#e6e6e6',
  gray: '#a1a1a1',
  grayDark: '#454545',
  red: '#c62828',
  redDark: '#631414',
};
