import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import siteConfig from '../../../config/siteConfig';
import { Context } from '../../state/store';
import '../../styles/global.css'; // imports google font
import { GlobalStyles } from './styles';

export default function Layout({
  metadata: { title, description, keywords },
  children,
}) {
  const [state] = useContext(Context);

  return (
    <React.Fragment>
      <Helmet
        title={title || siteConfig.meta.title}
        meta={[
          {
            name: 'description',
            content: description || siteConfig.meta.description,
          },
          {
            name: 'keywords',
            content: keywords || siteConfig.meta.keywords,
          },
        ]}
        bodyAttributes={{
          class: `body ${
            state.navigationIsOpen || state.importantMessageIsOpen
              ? 'body--modal-open'
              : ''
          }`,
        }}
      >
        <html lang="sv-SE" />
      </Helmet>
      <GlobalStyles />
      {children}
    </React.Fragment>
  );
}

Layout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  children: PropTypes.node.isRequired,
};
