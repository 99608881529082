import { createGlobalStyle } from 'styled-components';
import { colors, fontWeight, screenSize } from '../../styles/variables';

export const GlobalStyles = createGlobalStyle`

  html {
    font-size: 112.5%;
  }
  
  @media (min-width: ${screenSize.smMin}) {
    html {
      font-size: 125%;
    }
  }
  
  @media (min-width: ${screenSize.mdMin}) {
    font-size: 137.5%;
  }
  
  * {
    box-sizing: border-box;
  }
  
  svg:not(:root) {
    overflow: hidden;
  }
  
  img {
    vertical-align: middle;
  }

  .body {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: ${fontWeight.light};
    text-rendering: optimizeLegibility;
    margin: 0;
    background-color: ${colors.grayLight}
  
    &--modal-open {
        overflow: hidden;
        height: 100%;
    }
  }
`;
